import { useMutation } from '@apollo/client';
import { Button, Col, Form, Input, InputNumber, Modal, Row } from 'antd';
import { upperCase } from 'lodash';
import React from 'react';
import { MAX_AMOUNT_INPUT } from '../../common/constants';
import { CREATE_CUSTOMER_ACCOUNT } from './graphql/Mutations';

function CreateAccountModal({
  isModalOpen,
  setIsModalOpen,
  refetch,
  customerDetail,
}) {
  const [form] = Form.useForm();

  const [createAccount, { loading }] = useMutation(
    CREATE_CUSTOMER_ACCOUNT,
    {
      onCompleted() {
        setIsModalOpen(false);
        refetch();
      },
      onError: () => { },
    },
  );

  const onFinish = async (values) => {
    createAccount({
      variables: {
        input: {
          customerId: customerDetail?.id,
          prevBalanceCash: values?.prevBalanceCash,
          prevBalanceCheque: values?.prevBalanceCheque,
        },
      },
    });
  };

  return (
    <div id="add-edit-member-modal">
      <Modal
        centered
        title={
          <h3 className="m-0 mb-10">
            {upperCase(`Create Customer Account`)}
          </h3>
        }
        footer={null}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        open={isModalOpen}
        width={576}
      >
        <Form
          name="basic"
          initialValues={{
            fullName: customerDetail?.name,
            customCode: customerDetail?.customCode,
            prevBalanceCash: 0,
            prevBalanceCheque: 0,
          }}
          className="category-forms mt-16"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          form={form}
        >
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label="Full Name"
                name="fullName"
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Custom Code"
                name="customCode"
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label="Previous Balance Cash (₹)"
                name="prevBalanceCash"
                rules={[
                  {
                    whitespace: true,
                    required: true,
                    message: 'Please Cash Amount!',
                    type: 'number',
                    transform: (value) => Number(value),
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  parser={(value) => value.replace(/[^0-9.]/g, "")} // Parse back to number
                  placeholder="Enter amount"
                  max={MAX_AMOUNT_INPUT}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Previous Balance Cheque (₹)"
                name="prevBalanceCheque"
                rules={[
                  {
                    whitespace: true,
                    required: true,
                    message: 'Please Cheque Amount!',
                    type: 'number',
                    transform: (value) => Number(value),
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  parser={(value) => value.replace(/[^0-9.]/g, "")} // Parse back to number
                  placeholder="Enter amount"
                  max={MAX_AMOUNT_INPUT}
                />
              </Form.Item>
            </Col>
          </Row>

          <Button
            type="primary"
            htmlType="submit"
            className="full-width font-600 submit-btn"
            disabled={loading}
            loading={loading}
          >
            Create Customer Account
          </Button>
        </Form>
      </Modal>
    </div>
  );
}

export default CreateAccountModal;

import { useMutation } from '@apollo/client';
import { Button, Col, DatePicker, Form, Input, InputNumber, Modal, Radio, Row } from 'antd';
import dayjs from 'dayjs';
import { upperCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import { MAX_AMOUNT_INPUT, TRANSACTION_TYPE } from '../../common/constants';
import { CREATE_TRANSACTION, UPDATE_TRANSACTION } from './graphql/Mutations';

function AddEditTransactionModal({
  isModalOpen,
  setIsModalOpen,
  refetch,
  selectedRecord,
  setSelectedRecord,
  accountId,
}) {
  const defaultTransactionType = "INVOICE_GENERATED"
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [transactionType, setTransactionType] = useState(selectedRecord?.type || defaultTransactionType);

  useEffect(() => {
    if (selectedRecord?.transactionDate) {
      form.setFieldsValue({
        transactionDate: dayjs(selectedRecord.transactionDate),
      });
    } else {
      form.setFieldsValue({
        transactionDate: dayjs(),
      });
    }
  }, [selectedRecord, form]);

  const [createUpdateTransaction] = useMutation(
    selectedRecord ? UPDATE_TRANSACTION : CREATE_TRANSACTION,
    {
      onError: () => {
        setLoading(false);
      },
    },
  );

  const onFinish = async (values) => {
    setLoading(true);
    const inputFields = {
      ...values, accountId,
    }

    if (selectedRecord?.id) {
      delete inputFields.type;
      delete inputFields.accountId;
    }

    createUpdateTransaction({
      variables: {
        input: inputFields,
        where: {
          id: selectedRecord?.id,
        },
      },
    }).then((res) => {
      if (res?.data) {
        setLoading(false);
        setIsModalOpen(false);
        setSelectedRecord(null);
        refetch();
      }
    });
  };

  const disabledFutureDate = (current) => current && current.isAfter(dayjs().endOf("day"));

  return (
    <div id="add-edit-categories-modal">
      <Modal
        centered
        title={
          <h3 className="m-0 mb-10">
            {upperCase(`${selectedRecord ? 'Edit' : 'Add New'} Transaction`)}
          </h3>
        }
        footer={null}
        onCancel={() => {
          setIsModalOpen(false)
          setSelectedRecord(null)
        }}
        open={isModalOpen}
        width={576}
      >
        <Form
          form={form}
          name="basic"
          initialValues={{
            cashAmount: selectedRecord?.cashAmount,
            chequeAmount: selectedRecord?.chequeAmount,
            type: selectedRecord?.type || defaultTransactionType,
            remarks: selectedRecord?.remarks,
            billNo: selectedRecord?.billNo,
            lrNo: selectedRecord?.lrNo,
            transport: selectedRecord?.transport,
          }}
          className="category-forms mt-16"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="Date"
            name="transactionDate"
          >
            <DatePicker
              format="YYYY-MM-DD"
              disabledDate={disabledFutureDate}
              style={{ width: "100%" }}
              placeholder="Select Transaction Date"
            />
          </Form.Item>
          <Row gutter={10} align="middle">
            <Col span={12}>
              <Form.Item
                label="Cash Amount (₹)"
                name="cashAmount"
                rules={[
                  {
                    whitespace: true,
                    required: true,
                    message: 'Please Cash Amount!',
                    type: 'number',
                    transform: (value) => Number(value),
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  // formatter={formatCurrency} // Display formatted value
                  parser={(value) => value.replace(/[^0-9.]/g, "")} // Parse back to number
                  placeholder="Enter amount"
                  max={MAX_AMOUNT_INPUT}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Cheque Amount (₹)"
                name="chequeAmount"
                rules={[
                  {
                    whitespace: true,
                    required: true,
                    message: 'Please Cheque Amount!',
                    type: 'number',
                    transform: (value) => Number(value),
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  // formatter={formatCurrency} // Display formatted value
                  parser={(value) => value.replace(/[^0-9.]/g, "")} // Parse back to number
                  placeholder="Enter amount"
                  max={MAX_AMOUNT_INPUT}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Type"
            name="type"
            rules={[
              {
                required: true,
                message: 'Required!',
              },
            ]}
          >
            <Radio.Group
              disabled={selectedRecord?.id}
              options={Object.entries(TRANSACTION_TYPE).map(([key, value]) => ({
                label: value,
                value: key,
              }))}
              onChange={(e) => setTransactionType(e.target.value)}
            />
          </Form.Item>

          {transactionType === "INVOICE_GENERATED" ? (
            <Form.Item label="Remarks" name="remarks">
              <Input placeholder="Enter Remark" />
            </Form.Item>
          ) : (
            <div>
              <Row gutter={10} align="middle">
                <Col span={12}>
                  <Form.Item label="Bill Number" name="billNo">
                    <Input placeholder="Enter Bill Number" />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label="LR No" name="lrNo">
                    <Input placeholder="Enter LR No" />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item label="Transport" name="transport">
                <Input placeholder="Enter Transport" />
              </Form.Item>
            </div>
          )}
          <Button
            type="primary"
            htmlType="submit"
            className="full-width font-600 submit-btn"
            disabled={loading}
            loading={loading}
          >
            {upperCase(`${selectedRecord ? 'Update' : 'Add'} Transaction`)}
          </Button>
        </Form>
      </Modal>
    </div>
  );
}

export default AddEditTransactionModal;

import { useLazyQuery } from '@apollo/client';
import {
  Breadcrumb,
  Button,
  Descriptions,
  Divider,
  Popover,
  Spin,
  Tooltip,
} from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CurveArrowIcon,
  DeleteIcon,
  EditIcon,
  MoreIcon,
  VerifiedPersonIcon,
} from '../../assets/svg';
import { MODULES, ROUTES, TRANSACTION_TYPE, defaultDateFormat, formatCurrency } from '../../common/constants';
import TableComponent from '../../components/CommonTable';
import ConfirmActionModal from '../../components/ConfirmActionModal';
import DeleteConfirmModal from '../../components/DeleteConfirmModal';
import Portal from '../../components/Portal';
import AddEditTransactionModal from './AddEditTransactionModal';
import './customer-account.less';
import { CARRY_FORWARD_ACCOUNT_BAL, CLEAR_ACCOUNT_MUTATION, DELETE_TRANSACTION } from './graphql/Mutations';
import { GET_ACCOUNT_TRANSACTIONS, GET_CUSTOMER_ACCOUNT } from './graphql/Queries';

const initialTransactionFilter = {
  filters: { skip: 0, limit: 10 },
  sort: { sortOn: 'transactionDate', sortBy: 'DESC' },
};

const initialPaginationValue = {
  total: 0,
  current: 1,
};

const CustomerAccountDetails = () => {
  const { id } = useParams();

  const accountId = id;

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [sortedInfo, setSortedInfo] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isCustomActionModalOpen, setIsCustomActionModalOpen] = useState(false);
  const [customActionModalProps, setCustomActionModalProps] = useState({});

  const [accountTransactionFilter, setAccountTransactionFilter] = useState({
    ...initialTransactionFilter,
    filters: {
      ...initialTransactionFilter?.filters,
      accountId: id,
    },
  });

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const navigate = useNavigate();
  const [getAccountDetails, { data, loading }] = useLazyQuery(GET_CUSTOMER_ACCOUNT, {
    variables: {
      where: {
        id,
      },
    },
    fetchPolicy: 'network-only',
    onError() { },
  });

  const [
    getTransactions,
    { data: transactionsData, loading: transactionLoading },
  ] = useLazyQuery(GET_ACCOUNT_TRANSACTIONS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: 10,
        total: res?.transactions?.count,
      };
      setPaginationProp(pagination);
    },
    onError() { },
  });

  useEffect(() => {
    getAccountDetails();
    setAccountTransactionFilter({
      ...accountTransactionFilter,
      filters: {
        ...accountTransactionFilter?.filters,
        accountId: id,
      },
    });
    getTransactions({
      variables: {
        ...accountTransactionFilter,
        filters: {
          ...accountTransactionFilter?.filters,
          accountId: id,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchAccountDetails = () => {
    setSortedInfo({});
    setAccountTransactionFilter(initialTransactionFilter);
    setPaginationProp(initialPaginationValue);
    getAccountDetails();
    getTransactions({
      variables: {
        ...initialTransactionFilter,
        filters: { ...initialTransactionFilter?.filters, accountId: id },
      },
    });
  };

  const items = [
    {
      key: '1',
      label: 'Custom Code',
      children: data?.account?.customer?.customCode,
    },
    {
      key: '2',
      label: 'Full Name',
      children: data?.account?.customer?.name,
    },
    {
      key: '3',
      label: 'Location',
      children: `${data?.account?.customer?.city}, ${data?.account?.customer?.state}`,
    },
    {
      key: '4',
      label: 'Report Date',
      children: dayjs(data?.account?.createdAt).format(defaultDateFormat),
    },
    {
      key: '5',
      label: 'Gross Balance (₹)',
      children: formatCurrency(data?.account?.grossTotal),
      span: 1,
    },
    {
      key: '6',
      label: 'Previous Balance (₹)',
      children: formatCurrency(data?.account?.prevBalanceTotal),
      span: 1,
    },
    {
      key: '7',
      label: 'Payment Balance (₹)',
      children: formatCurrency(data?.account?.paymentTotal),
      span: 1,
    },
    {
      key: '8',
      label: 'Net Balance (₹)',
      children: formatCurrency(data?.account?.netTotal),
      span: 1,
    },
  ];

  const columns = [
    {
      title: '#',
      key: '#',
      align: 'center',
      width: '5%',
      render: (record, _, index) => (
        <div>{accountTransactionFilter?.filters?.skip + index + 1}</div>
      ),
    },
    {
      title: 'Transaction ID',
      dataIndex: 'code',
      key: 'code',
      width: '12%',
      align: 'center',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: '10%',
      render: (type) => (
        TRANSACTION_TYPE[type]
      ),
    },
    {
      title: 'Amount (₹)',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      isGroup: true,
      columns: [
        {
          title: 'Cash',
          dataIndex: 'cashAmount',
          key: 'cashAmount',
          align: 'center',
          render: (cashAmount) => (
            formatCurrency(cashAmount)
          ),
        },
        {
          title: 'Cheque',
          dataIndex: 'chequeAmount',
          key: 'chequeAmount',
          align: 'center',
          render: (chequeAmount) => (
            formatCurrency(chequeAmount)
          ),
        },
      ],
    },
    {
      title: 'Additional Info',
      align: 'center',
      width: '25%',
      render: (record) => {
        if (record.type === "INVOICE_GENERATED") {
          return record?.remarks ? `Remark: ${record.remarks}` : '-';
        }

        const parts = [];

        if (record.billNo) {
          parts.push(<div>Bill No: {record.billNo}</div>);
        }

        if (record.lrNo) {
          parts.push(<div>LR No: {record.lrNo}</div>);
        }

        if (record.transport) {
          parts.push(<div>Transport: {record.transport}</div>);
        }

        return parts.length > 0 ? (
          <div>{parts}</div>
        ) : '-';
      },
    },
    {
      title: 'Date',
      dataIndex: 'transactionDate',
      key: 'transactionDate',
      align: 'center',
      width: '10%',
      render: (transactionDate) => (
        dayjs(transactionDate).format(defaultDateFormat)
      ),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'transactionDate' && sortedInfo?.order,
    },
    {
      align: 'center',
      render: (text, record) => (
        <>
          <Tooltip title="Edit Transaction">
            <EditIcon
              className="mr-10 pointer"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedRecord(record);
                setIsModalOpen(true);
              }}
            />
          </Tooltip>
          <Tooltip title="Delete Transaction">
            <DeleteIcon
              className="pointer"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedRecord(record);
                setShowDeleteModal(true);
              }}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setAccountTransactionFilter({
        ...accountTransactionFilter,
        filters: {
          ...accountTransactionFilter?.filters,
          skip,
          limit: pagination.pageSize,
        },
        sort: {
          sortOn: sorter.field,
          sortBy: sorter.order === 'ascend' ? 'ASC' : 'DESC',
        },
      });
      getTransactions({
        variables: {
          ...accountTransactionFilter,
          filters: {
            ...accountTransactionFilter?.filters,
            skip,
            limit: pagination.pageSize,
            accountId: id,
          },
          sort: {
            sortOn: sorter.field,
            sortBy: sorter.order === 'ascend' ? 'ASC' : 'DESC',
          },
        },
      });
    } else {
      setAccountTransactionFilter({
        ...accountTransactionFilter,
        filters: {
          ...accountTransactionFilter?.filters,
          skip,
          limit: pagination.pageSize,
        },
        sort: { sortOn: 'transactionDate', sortBy: 'DESC' },
      });
      getTransactions({
        variables: {
          ...accountTransactionFilter,
          filters: {
            ...accountTransactionFilter?.filters,
            skip,
            limit: pagination.pageSize,
            accountId: id,
          },
          sort: { sortOn: 'transactionDate', sortBy: 'DESC' },
        },
      });
    }
  };

  const openCustomActionModal = ({ title, description, mutation, confirmButtonName, mutationVariables }) => {
    setCustomActionModalProps({ title, description, mutation, confirmButtonName, mutationVariables });
    setIsCustomActionModalOpen(true);
  };

  const moreActions = (
    <div className="py-5">
      <p
        className="d-flex align-center gap-8 mb-10 pointer"
        style={{ fontSize: '1rem' }}
        onClick={(e) => {
          e.stopPropagation();
          openCustomActionModal({
            title: 'Clear Account',
            description: 'Are you sure you want to clear this account? This action cannot be undone.',
            mutation: CLEAR_ACCOUNT_MUTATION,
            confirmButtonName: 'Clear',
            mutationVariables: { where: { id: accountId } },
          })
        }}
      >
        <span>
          <VerifiedPersonIcon />
        </span>
        Clear Account
      </p>
      <Divider style={{ margin: 0 }} />
      <p
        className="d-flex align-center gap-8 mt-10 pointer"
        style={{ fontSize: '1rem' }}
        onClick={(e) => {
          e.stopPropagation()
          openCustomActionModal({
            title: 'Carry Forward',
            description: 'Are you sure you want to carry forward this account balance?',
            mutation: CARRY_FORWARD_ACCOUNT_BAL,
            confirmButtonName: 'Proceed',
            mutationVariables: { where: { id: accountId } },
          })
        }}
      >
        <span>
          <CurveArrowIcon className="mt-6" />
        </span>
        Carry Forward
      </p>
    </div>
  );

  return (
    <div className="member-details">
      <Portal portalId="header-wrapper">
        <Breadcrumb
          className="d-flex align-center breadcrumb-title"
          items={[
            {
              title: (
                <h1 className="m-0 font-600">{MODULES.CUSTOMER_ACCOUNT}</h1>
              ),
              onClick: () => navigate(ROUTES.CUSTOMER_ACCOUNT),
              className: 'pointer',
            },
            {
              title: <h2 className="m-0 font-600">{data?.account?.customer?.code}</h2>,
            },
          ]}
        />

        <Button
          type="primary"
          className="font-600 submit-btn"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          GENERATE REPORT
        </Button>
      </Portal>

      <Spin spinning={loading}>
        <Descriptions
          layout="vertical"
          items={items}
          colon={false}
          column={4}
        />
      </Spin>
      <Divider dashed className="divider-dashed" />
      <div className="mt-20">
        <div className="d-flex justify-between align-center mb-24">
          <h2 className="m-0">TRANSACTIONS</h2>
          <div
            className="transaction-actions d-flex gap-10"
            style={{ alignItems: 'stretch' }}
          >
            <Button
              type="primary"
              className="font-600 submit-btn add-transaction"
              onClick={() => setIsModalOpen(true)}
            >
              ADD TRANSACTION
            </Button>
            <Popover
              content={moreActions}
              trigger="click"
              placement="bottomRight"
            >
              <Button type="primary" className="submit-btn pt-7">
                <MoreIcon />
              </Button>
            </Popover>
          </div>
        </div>
      </div>
      <TableComponent
        loadingData={transactionLoading}
        columns={columns}
        data={transactionsData?.transactions?.transactions}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(obj) => obj?.id}
      />
      {isModalOpen && (
        <AddEditTransactionModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          refetch={refetchAccountDetails}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
          accountId={id}
        />
      )}
      {showDeleteModal && (
        <DeleteConfirmModal
          isModalOpen={showDeleteModal}
          setIsModalOpen={setShowDeleteModal}
          refetch={refetchAccountDetails}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
          mutation={DELETE_TRANSACTION}
          module="transaction"
        />
      )}

      {isCustomActionModalOpen && (
        <ConfirmActionModal
          isModalOpen={isCustomActionModalOpen}
          setIsModalOpen={setIsCustomActionModalOpen}
          refetch={refetchAccountDetails}
          mutation={customActionModalProps.mutation}
          mutationVariables={customActionModalProps.mutationVariables}
          title={customActionModalProps.title}
          description={customActionModalProps.description}
          confirmButtonName={customActionModalProps.confirmButtonName}
        />
      )}
    </div>
  );
};

export default CustomerAccountDetails;

import { Table } from 'antd';
import React, { useEffect, useState } from 'react';

const TableComponent = (props) => {
  const {
    columns = [],
    data = [],
    onChange,
    loadingData = false,
    tableClassName = '',
    paginationConfig,
    ...rest
  } = props;

  const [paginationProps, setPaginationProps] = useState({
    pageSizeOptions: [10, 15, 20, 50],
    defaultPageSize: 10,
    responsive: true,
    showSizeChanger: true,
    position: ['bottomCenter'],
  });

  useEffect(() => {
    setPaginationProps((prev) => ({ ...prev, ...paginationConfig }));
  }, [paginationConfig]);

  return (
    <Table
      bordered={false}
      dataSource={data}
      className={`${tableClassName} table-component`}
      onChange={onChange}
      pagination={paginationConfig ? paginationProps : false}
      loading={{
        spinning: loadingData,
        size: 'large',
      }}
      {...rest}
    >
      {columns.map((col) =>
        col.isGroup ? (
          <Table.ColumnGroup key={col.key || col.dataIndex} title={col.title}>
            {col.columns.map((subCol) => (
              <Table.Column key={subCol.key || subCol.dataIndex} {...subCol} />
            ))}
          </Table.ColumnGroup>
        ) : (
          <Table.Column key={col.key || col.dataIndex} {...col} />
        ),
      )}
    </Table>
  );
};

export default TableComponent;

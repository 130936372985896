import { gql } from '@apollo/client';

export const GET_CUSTOMER_ACCOUNTS = gql`
  query Accounts($filters: AccountsFilterInput, $sort: AccountsSortInput) {
    accounts(filters: $filters, sort: $sort) {
      count
      accounts {
        id
        customerId
        grossTotal
        prevBalanceTotal
        paymentTotal
        netTotal
        customer {
          id
          name
          customCode
        }
        reportUrl {
          key
          url
        }
        reportDate
        createdAt
      }
    }
  }
`;

export const GET_CUSTOMER_ACCOUNT = gql`
  query Account($where: UniqueWhereInput!) {
    account(where: $where) {
      id
      customerId
      grossTotal
      prevBalanceTotal
      paymentTotal
      netTotal
      customer {
        id
        name
        customCode
        city
        state
        code
      }
    }
  }
`;

export const GET_ACCOUNT_TRANSACTIONS = gql`
  query Transactions($filters: TransactionsFilterInput!, $sort: TransactionsSortInput) {
    transactions(filters: $filters, sort: $sort) {
      count
      transactions {
        id
        transactionDate
        type
        cashAmount
        chequeAmount
        billNo
        lrNo
        transport
        remarks
        settled
        settledAt
        createdAt
        updatedAt
        code
      }
    }
  }
`
import { useMutation } from '@apollo/client';
import { Button, Col, Modal, Row } from 'antd';
import { upperCase } from 'lodash';
import React from 'react';

function ConfirmActionModal({
  isModalOpen,
  setIsModalOpen,
  refetch,
  mutation,
  mutationVariables,
  title,
  description,
  confirmButtonName = 'Confirm',
}) {
  const [executeMutation, { loading }] = useMutation(mutation, {
    onCompleted() {
      setIsModalOpen(false);
      refetch();
    },
    onError() { },
  });

  const handleConfirm = () => {
    executeMutation({
      variables: mutationVariables,
    });
  };

  return (
    <div id="confirm-action-modal">
      <Modal
        centered
        closable={false}
        title={<h3 className="m-0 mb-10">{upperCase(title)}</h3>}
        footer={null}
        onCancel={() => setIsModalOpen(false)}
        open={isModalOpen}
        width={576}
      >
        <p className="mb-16 mt-10">{description}</p>
        <Row gutter={10}>
          <Col span={12}>
            <Button
              disabled={loading}
              onClick={() => {
                setIsModalOpen(false);
              }}
              className="full-width font-600 submit-btn"
            >
              Cancel
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              htmlType="submit"
              disabled={loading}
              className="full-width font-600 submit-btn"
              loading={loading}
              onClick={handleConfirm}
            >
              {confirmButtonName}
            </Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}

export default ConfirmActionModal;

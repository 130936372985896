import { gql } from '@apollo/client';

export const CREATE_TRANSACTION = gql`
  mutation CreateTransaction($input: CreateTransactionInput!) {
    createTransaction(input: $input) {
      message
    }
  }
`;

export const UPDATE_TRANSACTION = gql`
  mutation UpdateTransaction($where: UniqueWhereInput!, $input: UpdateTransactionInput!) {
    updateTransaction(where: $where, input: $input) {
      message
    }
  }
`

export const DELETE_TRANSACTION = gql`
  mutation DeleteTransaction($where: UniqueWhereInput!) {
    deleteTransaction(where: $where) {
      message
    }
  }
`;

export const CLEAR_ACCOUNT_MUTATION = gql`
  mutation AccountClear($where: UniqueWhereInput!) {
    accountClear(where: $where) {
      message
    }
  }
`;

export const CARRY_FORWARD_ACCOUNT_BAL = gql`
  mutation CarryForward($where: UniqueWhereInput!) {
    carryForward(where: $where) {
      message
    }
  }
`
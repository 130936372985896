import { useLazyQuery } from '@apollo/client';
import { Col, Row, Tooltip } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DownloadIcon, EyeIcon, LedgerIcon } from '../../assets/svg';
import { MODULES, ROUTES, defaultDateFormat, formatCurrency } from '../../common/constants';
import { handlePdfDownload } from '../../common/utils';
import TableComponent from '../../components/CommonTable';
import Portal from '../../components/Portal';
import SearchComponent from '../../components/SearchComponent';
import { GET_CUSTOMER_ACCOUNTS } from './graphql/Queries';

const CustomerAccounts = () => {
  const initialCustomerAccountsFilter = {
    filters: { skip: 0, limit: 10 },
    sort: { sortOn: 'createdAt', sortBy: 'DESC' },
  };

  const initialPaginationValue = {
    total: 0,
    current: 1,
  };

  const navigate = useNavigate();
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [selectedRecord, setSelectedRecord] = useState(null);
  // const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [customerAccountsFilter, setCustomerAccountsFilter] = useState(
    initialCustomerAccountsFilter,
  );

  const [listCustomerAccounts, { loading, data }] = useLazyQuery(GET_CUSTOMER_ACCOUNTS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: 10,
        total: res?.contractors?.count,
      };
      setPaginationProp(pagination);
    },
    onError() { },
  });

  useEffect(() => {
    listCustomerAccounts({ variables: customerAccountsFilter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setSortedInfo(sorter);

    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setCustomerAccountsFilter({
        ...customerAccountsFilter,
        sort: {
          sortOn: sorter.field,
          sortBy: sorter.order === 'ascend' ? 'ASC' : 'DESC',
        },
      });
      listCustomerAccounts({
        variables: {
          ...customerAccountsFilter,
          filters: {
            skip,
            limit: pagination.pageSize,
          },
          sort: {
            sortOn: sorter.field,
            sortBy: sorter.order === 'ascend' ? 'ASC' : 'DESC',
          },
        },
      });
    } else {
      setCustomerAccountsFilter({
        ...customerAccountsFilter,
        filters: { skip, limit: pagination.pageSize },
        sort: { sortOn: 'createdAt', sortBy: 'DESC' },
      });
      listCustomerAccounts({
        variables: {
          ...customerAccountsFilter,
          filters: {
            skip,
            limit: pagination.pageSize,
          },
          sort: { sortOn: 'createdAt', sortBy: 'DESC' },
        },
      });
    }
  };

  const columns = [
    {
      title: '#',
      key: '#',
      width: '5%',
      align: 'left',
      render: (record, _, index) => (
        <div>{customerAccountsFilter?.filters?.skip + index + 1}</div>
      ),
    },
    {
      title: 'Customer Code',
      dataIndex: ["customer", "customCode"],
      key: 'customerCode',
      width: '10%',
      align: 'left',
    },
    {
      title: "Full Name",
      dataIndex: ["customer", "name"],
      key: 'firstName',
      align: 'left',
    },
    {
      title: 'Gross Balance (₹)',
      dataIndex: 'grossTotal',
      key: 'grossTotal',
      align: 'left',
      render: (record) => <div>{formatCurrency(record)}</div>,
    },
    {
      title: 'Previous Balance (₹)',
      key: 'prevBalanceTotal',
      dataIndex: 'prevBalanceTotal',
      align: 'left',
      render: (record) => <div>{formatCurrency(record)}</div>,
    },
    {
      title: 'Payment Balance (₹)',
      dataIndex: 'paymentTotal',
      key: 'paymentTotal',
      align: 'left',
      render: (record) => <div>{formatCurrency(record)}</div>,
    },
    {
      title: 'Net Balance (₹)',
      dataIndex: 'netTotal',
      key: 'netTotal',
      align: 'left',
      render: (record) => <div>{formatCurrency(record)}</div>,
    },
    {
      title: 'Report Date',
      dataIndex: 'reportDate',
      key: 'reportDate',
      align: 'left',
      render: (date) => date ? dayjs(date).format(defaultDateFormat) : '-',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'createdAt' && sortedInfo?.order,
    },
    {
      dataIndex: 'reportUrl',
      key: 'reportUrl',
      align: 'center',
      render: (reportUrl) => reportUrl?.url ? (
        <div className="d-flex justify-center">
          <Tooltip title="View Report">
            <EyeIcon
              className="mr-10 pointer"
              onClick={(e) => {
                e.stopPropagation();
                if (reportUrl?.url) {
                  // eslint-disable-next-line no-undef
                  window.open(reportUrl?.url, '_blank');
                }
              }}
            />
          </Tooltip>
          <Tooltip title="Download Report">
            <DownloadIcon
              className="pointer"
              onClick={(e) => {
                e.stopPropagation();
                handlePdfDownload(reportUrl?.url, reportUrl?.key?.split('/')?.[2]);
              }}
            />
          </Tooltip>
        </div>
      ) : (
        '-'
      ),
    },
    {
      align: 'center',
      render: () => (
        <>
          <Tooltip title="Monthly Ledger">
            <LedgerIcon
              className="mr-10 pointer"
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  const onSearchChange = async (value) => {
    setCustomerAccountsFilter({
      ...customerAccountsFilter,
      filters: {
        ...customerAccountsFilter?.filters,
        skip: 0,
        search: value,
      },
    });
    listCustomerAccounts({
      variables: {
        ...customerAccountsFilter,
        filters: {
          ...customerAccountsFilter?.filters,
          skip: 0,
          search: value,
        },
      },
    });
  };

  return (
    <div>
      <Portal portalId="header-wrapper">
        <h1 className="m-0 font-600">{MODULES.CUSTOMER_ACCOUNT}</h1>
      </Portal>
      <Row className="mb-24" justify="space-between">
        <Col>
          <SearchComponent
            id="search-container-id"
            name="customer..."
            getData={onSearchChange}
            className="category-search search-component"
          />
        </Col>
      </Row>
      <TableComponent
        loadingData={loading}
        columns={columns}
        data={data?.accounts?.accounts}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(obj) => obj.id}
        onRow={(record) => ({
          onClick: () => {
            navigate(`${ROUTES.CUSTOMER_ACCOUNT}/${record?.id}`);
          },
        })}
        rowClassName="pointer"
        scroll={{ y: 472 }}
      />
    </div>
  );
};

export default CustomerAccounts;

import { useMutation } from '@apollo/client';
import { Button, Col, Modal, Row } from 'antd';
import { upperCase } from 'lodash';
import React from 'react';

function DeleteConfirmModal({
  isModalOpen,
  setIsModalOpen,
  refetch,
  selectedRecord,
  setSelectedRecord,
  module = 'category',
  mutation,
}) {
  const [deleteRecord, { loading }] = useMutation(mutation, {
    onCompleted() {
      setIsModalOpen(false);
      setSelectedRecord();
      refetch();
    },
    onError(e) {
      // eslint-disable-next-line no-console
      console.log(e);
    },
  });

  const handleDelete = () => {
    deleteRecord({
      variables: {
        where: {
          id: selectedRecord?.id,
        },
      },
    });
  };

  return (
    <div id="delete-categories-modal">
      <Modal
        centered
        closable={false}
        title={<h3 className="m-0 mb-10">{upperCase(`Delete ${module}`)}</h3>}
        footer={null}
        onCancel={() => setIsModalOpen(false)}
        open={isModalOpen}
        width={576}
      >
        <p className="mb-16 mt-10">
          Are you sure you want to delete this {module}?
        </p>
        <Row gutter={10}>
          <Col span={12}>
            <Button
              disabled={loading}
              onClick={() => {
                setIsModalOpen(false);
                setSelectedRecord();
              }}
              className="full-width font-600 submit-btn"
            >
              Cancel
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              htmlType="submit"
              disabled={loading}
              className="full-width font-600 submit-btn"
              loading={loading}
              onClick={() => handleDelete()}
            >
              Delete
            </Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}

export default DeleteConfirmModal;

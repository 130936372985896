import { Menu } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import {
  CategoryIcon,
  CustomerIcon,
  MemberIcon,
  OrderIcon,
  CustomerAccountIcon,
} from '../../../assets/svg';
import { MODULES, ROLE_KEYS, ROUTES } from '../../../common/constants';
import { getItem } from '../../../common/utils';
import useRouter from '../../../hooks/useRouter';
import UserProfile from '../header/UserProfile';

const menuItems = (isSuperAdmin) => [
  getItem(
    MODULES?.CATEGORIES,
    ROUTES?.CATEGORIES,
    <CategoryIcon height={18} width={18} />,
  ),
  isSuperAdmin &&
    getItem(
      MODULES?.MEMBERS,
      ROUTES?.MEMBERS,
      <MemberIcon height={18} width={18} />,
    ),
  getItem(
    MODULES?.CUSTOMER,
    ROUTES?.CUSTOMER,
    <CustomerIcon height={18} width={18} />,
  ),
  getItem(
    MODULES?.ORDER_AND_BILLING,
    ROUTES?.ORDER_AND_BILLING,
    <OrderIcon height={18} width={18} />,
  ),
  getItem(
    MODULES?.CONTRACTOR,
    ROUTES?.CONTRACTOR,
    <MemberIcon height={18} width={18} />,
  ),
  getItem(
    MODULES?.CUSTOMER_ACCOUNT,
    ROUTES?.CUSTOMER_ACCOUNT,
    <CustomerAccountIcon height={18} width={18} />,
  ),
];

function Sidebar() {
  const {
    navigate,
    location: { pathname },
  } = useRouter();
  const { getCurrentUser } = useContext(AppContext);
  const isSuperAdmin = getCurrentUser()?.role === ROLE_KEYS.SUPER_ADMIN;
  const onMenuSelect = (e) => {
    navigate(e?.key);
  };

  return (
    <div className="side-bar">
      <Menu
        theme="light"
        mode="inline"
        selectedKeys={[`/${pathname?.split('/')?.[1]}`]}
        defaultSelectedKeys={[ROUTES?.MAIN]}
        onSelect={onMenuSelect}
        onClick={onMenuSelect}
        items={menuItems(isSuperAdmin)}
      />
      <UserProfile menuItems={menuItems} />
    </div>
  );
}

export default Sidebar;

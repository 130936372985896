import { useLazyQuery } from '@apollo/client';
import { Button, Col, Row } from 'antd';
import dayjs from 'dayjs';
import { trim } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeleteIcon, EditIcon, OrderHistory } from '../../assets/svg';
import { MODULES, ROUTES, defaultDateFormat } from '../../common/constants';
import TableComponent from '../../components/CommonTable';
import DeleteConfirmModal from '../../components/DeleteConfirmModal';
import Portal from '../../components/Portal';
import SearchComponent from '../../components/SearchComponent';
import ActivateDeactivateModal from './ActivateDeactivateModal';
import AddEditCustomerModal from './AddEditCustomerModal';
import './customer.less';
import { DELETE_CUSTOMER } from './graphql/Mutations';
import { CUSTOMER } from './graphql/Queries';

const Customer = () => {
  const navigate = useNavigate();
  const initialCustomerFilter = {
    skip: 0,
    limit: 10,
  };

  const initialCustomerSort = {
    sortOn: 'createdAt',
    sortBy: 'DESC',
  };

  const initialPaginationValue = {
    total: 0,
    current: 1,
  };
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [customerFilter, setCustomerFilter] = useState(initialCustomerFilter);
  const [customerSort, setCustomersSort] = useState(initialCustomerSort);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [search, setSearch] = useState('');

  const [getCustomer, { loading, data }] = useLazyQuery(CUSTOMER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: 10,
        total: res?.customers?.count,
      };
      setPaginationProp(pagination);
    },
    onError() { },
  });

  useEffect(() => {
    getCustomer({
      variables: {
        filters: { ...customerFilter, search },
        sort: customerSort,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchUserDetails = () => {
    setSortedInfo({});
    setCustomerFilter(initialCustomerFilter);
    setPaginationProp(initialPaginationValue);
    getCustomer({
      variables: { ...initialCustomerFilter, ...initialCustomerSort },
    });
  };

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setCustomersSort({
        sortOn: sorter.field,
        sortBy: sorter.order === 'ascend' ? 'ASC' : 'DESC',
      });
      setCustomerFilter({
        ...customerFilter,
        skip,
        limit: pagination.pageSize,
      });
      getCustomer({
        variables: {
          filters: {
            ...customerFilter,
            skip,
            limit: pagination.pageSize,
          },
          sort: {
            sortOn: sorter.field,
            sortBy: sorter.order === 'ascend' ? 'ASC' : 'DESC',
          },
        },
      });
    } else {
      setCustomersSort({
        sortOn: 'createdAt',
        sortBy: 'DESC',
      });
      setCustomerFilter({
        ...customerFilter,
        skip,
        limit: pagination.pageSize,
      });
      getCustomer({
        variables: {
          filters: {
            ...customerFilter,
            skip,
            limit: pagination.pageSize,
          },
          sort: { sortOn: 'createdAt', sortBy: 'DESC' },
        },
      });
    }
  };

  const columns = [
    {
      title: '#',
      key: '#',
      width: '10%',
      align: 'center',
      render: (record, _, index) => (
        <div>{customerFilter?.skip + index + 1}</div>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
    },
    {
      title: 'Custom Code',
      dataIndex: 'customCode',
      key: 'customCode',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'customCode' && sortedInfo?.order,
    },
    {
      title: 'Phone No',
      dataIndex: 'phoneNo',
      key: 'phoneNo',
    },
    {
      title: 'Account Created',
      dataIndex: 'accountCreated',
      key: "accountCreated",
      align: 'center',
      render: (accountCreated) => accountCreated === true ? 'Yes' : 'No',
    },
    {
      title: 'Added On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'createdAt' && sortedInfo?.order,
      render: (date) => dayjs(date).format(defaultDateFormat),
    },
    {
      align: 'center',
      render: (id, record) => (
        <div className="d-flex align-center">
          <OrderHistory
            className="mr-10 pointer"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedRecord(record);
              navigate(ROUTES.ORDER_AND_BILLING, {
                state: { customerId: record?.id },
              });
            }}
          />
          <EditIcon
            className="mr-10 pointer"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedRecord(record);
              setIsModalOpen(true);
            }}
          />
          <DeleteIcon
            className="pointer"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedRecord(record);
              setShowDeleteModal(true);
            }}
          />
        </div>
      ),
    },
  ];

  const onSearchChange = async (value) => {
    setSearch(trim(value));

    setCustomerFilter({ ...customerFilter, skip: 0, search: value });
    getCustomer({
      variables: {
        sort: customerSort,
        filters: {
          ...customerFilter,
          skip: 0,
          search: trim(value),
        },
      },
    });
  };

  return (
    <div className="categories-module">
      <Portal portalId="header-wrapper">
        <h1 className="m-0 font-600">{MODULES.CUSTOMER}</h1>
      </Portal>
      <Row className="mb-10" justify="space-between">
        <Col>
          <SearchComponent
            id="search-container-id"
            name="customer"
            getData={onSearchChange}
            className="category-search search-component"
          />
        </Col>
        <Col>
          <Button
            type="primary"
            className="add-new-btn"
            onClick={() => setIsModalOpen(true)}
          >
            Add New
          </Button>
        </Col>
      </Row>
      <TableComponent
        loadingData={loading}
        columns={columns}
        data={data?.customers?.customers}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(obj) => obj?.id}
        onRow={(record) => ({
          onClick: () => {
            navigate(`${ROUTES.CUSTOMER}/${record?.id}`);
          },
        })}
        rowClassName="pointer"
      />
      {isModalOpen && (
        <AddEditCustomerModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          refetch={refetchUserDetails}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
        />
      )}
      {showDeleteModal && (
        <DeleteConfirmModal
          isModalOpen={showDeleteModal}
          setIsModalOpen={setShowDeleteModal}
          refetch={refetchUserDetails}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
          mutation={DELETE_CUSTOMER}
          module="customer"
        />
      )}
      {showChangeStatusModal && (
        <ActivateDeactivateModal
          isModalOpen={showChangeStatusModal}
          setIsModalOpen={setShowChangeStatusModal}
          refetch={refetchUserDetails}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
        />
      )}
    </div>
  );
};
export default Customer;
